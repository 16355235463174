<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="12">
            <vue-good-table
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="onShowStudentClassModal(props.row.id)">
                      <feather-icon icon="UsersIcon" />
                      <span class="align-middle ml-50">Danh sách sinh viên</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onShowClassNotificationModal(props.row.id)">
                      <feather-icon icon="Volume2Icon" />
                      <span class="align-middle ml-50">Thông báo</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <StudentClass :class-id="classId" />
    <ClassNotification :class-id="classId" />
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCol, BOverlay, BRow, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES } from '@/const/selection'
import ClassNotification from '@/views/study/class/ClassNotification.vue'
import StudentClass from '@/views/study/class/StudentClass.vue'

export default {
  name: 'Class',
  components: {
    StudentClass,
    ClassNotification,
    BCol,
    BRow,
    BCard,
    BCardBody,
    BOverlay,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      columns: [
        {
          field: 'name',
          label: 'Tên lớp',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'code',
          label: 'Mã lớp',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'teacher',
          label: 'Giáo viên CN',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'consultant',
          label: 'Cố vấn HT',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'status',
          label: 'Trạng thái',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'action',
          label: '',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      paginationOptions: {
        enabled: false,
      },
      classId: null,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'study/classes',
    }),
  },
  async created() {
    await this.getDataSourceFromStore()
    const { groupId } = this.$route.query
    if (groupId) {
      // eslint-disable-next-line radix
      this.onShowClassNotificationModal(groupId)
    }
  },

  beforeRouteUpdate(to, from, next) {
    const newGroupId = to.query.groupId
    if (newGroupId) {
      this.onShowClassNotificationModal(newGroupId)
    }
    next() // Tiếp tục điều hướng
  },
  methods: {
    ...mapMutations({
      setDataSources: 'study/SET_CLASSES',
    }),
    ...mapActions({
      getClassesByStudentAndProgramme: 'study/getClassesByStudentAndProgramme',
    }),
    async getDataSourceFromStore() {
      const programmeId = this.user.portalProgrammeId
      if (!programmeId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sinh viên không theo học chương trình đào tạo nào',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.setDataSources([])
        return
      }
      this.isLoading = true
      try {
        await this.getClassesByStudentAndProgramme({ studentId: this.user.studentId, programmeId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    onShowStudentClassModal(id) {
      this.classId = id
      this.$bvModal.show('studentClassModal')
    },
    onShowClassNotificationModal(id) {
      this.classId = parseInt(id)
      this.$bvModal.show('classNotificationModal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>
